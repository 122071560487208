<template>
  <div class="bukaos">
    <div class="export">
      <h3>补考名单列表</h3>
      <el-button
        type="success"
        :disabled="datas.length <= 0"
        plain
        @click="exportPDF"
        >导出补考名单</el-button
      >
    </div>
    <div class="content" ref="pdf">
      <div class="bukao" v-for="item in datas" :key="item.zhoukao">
        <div class="class">
          <h3>{{ item.className }}</h3>
          <el-button type="primary" plain @click="clickAction(item)"
            >补考题下载</el-button
          >
        </div>
        <div class="stus">
          <div
            :class="{ stu: true, normal: item1.kaoshi <= 60 }"
            v-for="item1 in item.t_scores"
            :key="item1.id"
          >
            <div v-if="item1.kaoshi <= 60">{{ item1.stu.stu_name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { downloadPDF } from "@/common/pdf.js";
export default {
  name: "BuKao",
  data() {
    return {
      datas: [],
    };
  },
  created() {
    this.datas =
      this.$route.query.datas[0] instanceof Object
        ? this.$route.query.datas
        : JSON.parse(localStorage.getItem("bk_datas"));
    localStorage.setItem("bk_datas", JSON.stringify(this.datas));
  },
  methods: {
    clickAction(item) {
      this.$router.push({
        name: "PDF",
        query: {
          datas: {
            tms: JSON.parse(item.timu),
          },
          zhoukaoName: item.zhoukaoName,
        },
      });
    },
    exportPDF() {
      downloadPDF(this.$refs.pdf, "补考名单");
    },
  },
};
</script>
<style lang="less" scoped>
.export {
  width: 96%;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
}
.bukao {
  width: 98%;
  padding: 20px 0;
  border-bottom: 1px solid #ccc;
  h3 {
    color: #555;
    padding: 15px 0;
  }
  .class {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
  }
  .stus {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .normal {
      padding: 8px 25px;
      border-radius: 5px;
      border: 1px solid #555;
      margin: 10px 15px;
    }
  }
}
</style>
